const tableDataComplex = [
  {
    name: "Marketplace",
    tech: ["apple", "android", "windows"],
    date: "12.Jan.2021",
    progress: 75.5,
  },
  {
    name: "Venus DB PRO",
    tech: ["apple"],
    date: "21.Feb.2021",
    progress: 35.4,
  },
  {
    name: "Venus DS",
    tech: ["apple", "windows"],
    date: "13.Mar.2021",
    progress: 25,
  },
  {
    name: "Venus 3D Asset",
    tech: ["apple", "android", "windows"],
    date: "24.Jan.2021",
    progress: 100,
  },
  {
    name: "Marketplace",
    tech: ["apple", "windows"],
    date: "Oct 24, 2022",
    progress: 75.5,
  },
  {
    name: "Marketplace",
    tech: ["apple", "android", "windows"],
    date: "Oct 24, 2022",
    progress: 75.5,
  },
  {
    name: "Marketplace",
    tech: ["apple", "android", "windows"],
    date: "12.Jan.2021",
    progress: 75.5,
  },
  {
    name: "Venus DB PRO",
    tech: ["apple"],
    date: "21.Feb.2021",
    progress: 35.4,
  },
  {
    name: "Venus DS",
    tech: ["apple", "windows"],
    date: "13.Mar.2021",
    progress: 25,
  },
  {
    name: "Venus 3D Asset",
    tech: ["apple", "android", "windows"],
    date: "24.Jan.2021",
    progress: 100,
  },
  {
    name: "Marketplace",
    tech: ["apple", "windows"],
    date: "Oct 24, 2022",
    progress: 75.5,
  },
];
export default tableDataComplex;
